import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import useTranslation from "../../mlang/useTranslation"
import BackgroundImage from "gatsby-background-image"

const Services = () => {
  const { services } = useTranslation('home')

  const { allFile: { images } } = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "services"}}, sort: {fields: name}) {
        images: nodes {
          name
          image: childImageSharp {
            fluid(fit: CONTAIN, quality:100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  return (
    <section className="main-container services" id="services">
      <h2 className="main-container__title">{services.title}</h2>
      <div className="cards">
        {services.list.map((item, i) => (
          <div key={item.title} className="card">
            <BackgroundImage 
              className="image"
              fluid={images[i].image.fluid}
              alt={images[i].name}
            />
            <div className="card__text">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Services