import React from "react"

import withLayout from "../components/layout/withLayout"
import SEO from "../components/seo"
import Jumbotron from "../components/home/jumbotron"
import Services from "../components/home/services"
import Infracut from "../components/home/infracut"
import Infraweld from "../components/home/infraweld"
import Inframark from "../components/home/inframark"
import Infraspec from "../components/home/infraspec"
import useTranslation from "../mlang/useTranslation"

const IndexPage = () => {
  const { seo } = useTranslation("home")

  return (
    <>
      <SEO {...seo} />
      <Jumbotron />
      <Services />
      <Infracut />
      <Infraweld />
      <Inframark />
      <Infraspec />
    </>
  )
}

export default withLayout(IndexPage)
