import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import useTranslation from "../../mlang/useTranslation"
import BackgroundImage from "gatsby-background-image"

const Jumbotron = () => {
  const { heading } = useTranslation("home")

  const {
    file: { image },
  } = useStaticQuery(graphql`
    {
      file(name: { eq: "header" }) {
        image: childImageSharp {
          fluid(quality: 100) {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="jumbotron">
      <BackgroundImage
        className="jumbotron__content"
        alt={image.fluid.originalName}
        fluid={image.fluid}
      >
        <h1>{heading.h1}</h1>
        <h2>{heading.h2.getHTML()}</h2>
      </BackgroundImage>
    </section>
  )
}

export default Jumbotron
