import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import MLink from "../../mlang/mlink"
import useTranslation from "../../mlang/useTranslation"
import InfraCarousel from "./infraCarousel"
import BackgroundImage from "gatsby-background-image"

const Infracut = () => {
  const home = useTranslation("home")
  const sort = ["-cub", "-l", "-lct", "-t", "-f", "-co2", "-t6000"]

  const {
    allFile: { images },
  } = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "cutting" } }
        sort: { fields: name }
      ) {
        images: nodes {
          name
          relativeDirectory
          image: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  let listOfEquipments = []
  for (let { name } of images) {
    listOfEquipments.push(name)
  }

  const translations = useTranslation(listOfEquipments)

  let sortedImagesList = []

  for (let i of sort) {
    let regex = new RegExp(`${i}`)
    for (let image of images) {
      if (image.name.match(regex)) {
        sortedImagesList.push(image)
        break
      }
    }
  }

  return (
    <section className="main-container accent" id="equipments">
      <h2 className="main-container__title">{home.infracut}</h2>
      <InfraCarousel>
        {sortedImagesList.map(({ name, relativeDirectory, image }) => (
          <div className="card" key={name}>
            <MLink to={`/${relativeDirectory}/${name}`}>
              <BackgroundImage
                className="image"
                fluid={image.fluid}
                alt={name}
              />
              <div className="card__text">
                <h2 className="card__purpose">{translations[name].purpose}</h2>
                <h3 className="card__title">
                  {translations[name].title.getHTML()}
                </h3>
              </div>
            </MLink>
          </div>
        ))}
      </InfraCarousel>
    </section>
  )
}

export default Infracut
